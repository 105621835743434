.sliderArrowsAnnotationContainer {
    transition: transform 0.2s ease;
    z-index: 20;
    position: fixed;
    bottom: 0;
    left: 5%;
    width: calc(100% - 48px);
    height: 300px;
    min-height: 84px;
    max-height: 35%;
    border-radius: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    font-family: GilroyBold;
}
.sliderArrowsAnnotationContainer button {
    z-index: 21;
    position: absolute;
    background: none;
    border: none;
    height: 64px;
    width: 64px;
    filter: drop-shadow(0 0 0.1rem black);
}
.sliderArrowsAnnotationContainer button:first-of-type{
    left: 10px;
}
.sliderArrowsAnnotationContainer button:last-of-type{
    right: 10px;
}
.sliderArrowAnnotationHeader{
    height: 64px;
    width: 64px;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
}
.sliderArrowAnnotationTitle {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-family: GilroyBold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
    z-index: 19;
    color: #0d0d0d;
    text-align: center;
}
.sliderArrowAnnotationContent {
    position: absolute;
    top: 70px;
    text-align: left;
    left: 20px;
    height: calc(
            100% - 48px - 32px
    );
    padding-left: 10px;
    font-family: GilroyMedium;
    width: calc(90% - 16px);
    z-index: 19;
    color: #0d0d0d;
    border-radius: 24px;
}

.arrow {
    width: 25px;
    height: 25px;
}



