.iconMenu {
    position: absolute;
    top: 116px;
    right: 16px;
  
    width: 52px;
    padding: 4px 0px;
    background-color: rgba(120, 120, 120, 0.7);
    border-radius: 24px;
  
    transition: transform 0.2s ease-out;
    z-index: 30;
  }
  
  .menuItem {
    position: relative;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }
  
  .menuItem img {
    height: 40%;
    width: auto;
  }
  
  .menuItem p {
    width: 40%;
    height: 40%;
    color: white;
    font-family: Slate;
  }