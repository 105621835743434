.introContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  min-height: 100px; 
  max-height: 27%; 
  border-radius: 24px;
  background-color: white;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: GilroyMedium;
  z-index: 20;
}

.introduction {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Add space between title and content */
}

.introductionTitle {
  font-size: 14px;
  color: #1E63BE;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.introduction p {
  margin: 0;
  padding: 0;
  text-align: left;
}



  