#imageAnnotationContainer {
    position: absolute;
    width: calc(100% - 48px);
    height: auto;
    max-height: calc(60% - 96px - 28px);
    padding: 16px 12px 12px;
    bottom: 24px;
    left: 24px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(8px);
    transition: transform 0.2s ease;
    z-index: 20;
}

.annotationImage {
    width: 100%;
    object-fit: contain;
}
.closeImage {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 16px;
    height: auto;
    z-index: 30;
}
