#annotationContainer {
  position: absolute;
  width: calc(100% - 48px);
  height: 230px;
  bottom: 24px;
  left: 24px;

  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(8px);
  transition: transform 0.2s ease;
  z-index: 20;
}
.heightContainer{
  height: 400px!important;
}
.heightContainer #annotationBody{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#annotationBody img{
  width: 70%;
}
#annotationImageContainer {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1.5px solid #fff;
  border-radius: 50%;
  bottom: 57%;
  right: 19%;
  transition: transform 0.2s ease;
  z-index: 20;
}

#annotationImageContainer img {
  width: 300%;
  height: 300%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#annotationBody {
  position: absolute;
  top: calc(32px + 28px); /* The height of the header plus the desired space */
  right: 5%;
  width: 90%;
  height: calc(
    100% - 48px - 32px
  ); /* Adjust the height to take into account the header and spacing */
  border-radius: 16px;
}

#annotationBody p {
  box-sizing: border-box;
  text-align: left;
  font-family: GilroyMedium;
  margin-top: 12px;
}
#imageExtra{
  margin-top: 6px;
  max-height: 100%;
}

#annotationTitle {
  position: absolute;
  width: 90%;
  height: 24px;
  top: calc(
    48px - 32px
  ); /* Adjust the height to place the title directly below the header */
  right: 5%;
  display: flex;
  justify-content: flex-start;
  font-family: GilroyBold;
  font-size: 24px;
}

#annotationTitle p {
  text-align: left;
}

.annotationHeader {
  position: absolute;
  width: 90%;
  height: 32px;
  top: 8px;
  right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerTitle {
  font-size: 14px;
  font-family: GilroyMedium;
  color: #9945ff;
}

.closeAnnotationImage {
  position: absolute;
  right: 0;
  top: 8px;
  width: 16px;
  height: auto;
  z-index: 30;
}

span.subscript {
  vertical-align: sub;
  font-size: 12px;
}

span.superscript {
  vertical-align: super;
  font-size: 12px;
}

.smaller-title-annotation {
  font-size: 16px;
}