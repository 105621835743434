/* ANIMATIONS */

.slide-left {
  transform: translateX(-100vmin);
}

.slide-right {
  transform: translateX(100vmin);
}

.slide-down {
  transform: translateY(150%);
}

.shrink-annotation {
  transform: scale(0.6) translateY(-62vmax);
}

.shrink-complete {
  transform: scale(0);
}

/* ANIMATIONS ICON PANEL WHEN QUIZ IS OPEN*/

.topPosition .flashcardContainer {
  transform: translateX(-238px);
}

.topPosition .iconMenu {
  animation: goToTopPosition 0.2s forwards;
  display: flex;
  padding: 0 4px;
  width: auto;
}

@keyframes goToTopPosition {
  100% {
    transform: translateY(-69px) translateX(0);
  }
}