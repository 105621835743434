.sliderAnnotationContainer {
    border-radius: 24px;
    background-color: white;
    backdrop-filter: blur(8px);
    transition: transform 0.2s ease;
    z-index: 19;
    position: fixed;   
    left: 5%;
    width: 90%;
    min-height: 84px;
    max-height: 30%;
    box-sizing: border-box;
    color: white;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: GilroyBold;
}

.sliderAnnotationShort {
    height: 18%;
}

.sliderAnnotationDefault {
    height: 20%;
    bottom: 10%;
}
.height{
    height: 35%;
    max-height: 40%;
    justify-content: flex-start;
    padding: 10px 20px!important;
    bottom: 5% !important;
}
.sliderAnnotationTitle {
    font-family: GilroyBold;
    text-align: left;
    font-size: 20px;
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: column;
    z-index: 19;
    color: #0d0d0d;
}
.sliderAnnotationContent {
    font-family: GilroyMedium;
    text-align: left;
    font-size: 16px;
    width: 100%;
    display: flex;
    z-index: 19;
    color: #0d0d0d;
}
.sliderAnnotationContent p {
    margin: 5px 0;
}
.rangeContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

}
.range-wrap {
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.range {
    width: 100%;
}
.bubble {
    font-family: GilroyBold;
    color: white;
    padding: 4px 12px;
    position: absolute;
    top: 7px;
    font-weight: bold;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    z-index: 30;
}
.bubble::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    top: -1px;
    left: 50%;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    z-index: 30;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #D9D9D9;
    border-radius: 10px;
}
input[type=range]::-webkit-slider-thumb {
    border: none;
    height: 40px;
    width: 40px;
    padding: 4px;
    border-radius: 50%;
    background: #7A6CF4;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -18px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}


