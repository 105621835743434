.cardContainer {
  position: absolute;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  top: 24px;
  left: 24px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  display: flex; /* Add flex */
  flex-direction: column; /* Set flex direction to column */
  align-items: center; /* Align items to center */
  padding: 42px 16px 16px 16px; /* Add padding to the container */
  box-sizing: border-box; /* Add box-sizing */
  transition: transform 0.2s ease-out;
  z-index: 30;
}

.cardBack {
  position: absolute;
  top: 16px;
  right: 16px;

  width: 16px;
  height: 16px;
}

.cardHead {
  width: 90%;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px; /* Add margin for spacing */
  top: 32px; /* Move the header down by 32 pixels */
}

.cardHead h1 {
  font-size: 32px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: GilroyBold;
}

.cardBody {
  width: calc(90% + 12px); /* Subtract 10px from the width */
  flex-grow: 1; /* Allow the body to take up the available space */
  box-sizing: border-box;
  font-family: 'GilroyMedium', sans-serif; /* Apply the Gilroy font family */
  overflow-y: auto; /* Add scrolling for overflowing content */
  max-height: 68vh; /* Adjust this value according to your requirements */
  min-height: 100px; /* Adjust this value according to your requirements */
  padding-right: 12px; /* Add 10px padding to the right */
}

.cardImage {
  height: auto; /* Set height to auto to preserve aspect ratio */
  width: 90%; /* Same width as cardBody */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage img {
  max-width: 100%;
}

.cardBodyExtra {
  width: 90%;
  margin-top: 8px; /* Add margin for spacing */
  font-family: 'GilroyMedium', sans-serif; /* Apply the Gilroy font family */
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: left;
}


/* OLD STUFF
.cardContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.45);
  
    backdrop-filter: blur(8px);
  
    transition: transform 0.2s ease-out;
  
    z-index: 30;
  }
  
  .cardBack {
    position: absolute;
    top: 8px;
    left: 8px;
  
    width: 64px;
    height: 64px;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5));
  }
  
  .cardHead {
    position: absolute;
    top: 20%;
    right: 25%;
    width: 50%;
    height: 10%;
  
    background-color: rgba(255, 255, 255, 1);
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5));
    border-radius: 16px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardHead h1 {
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .cardBody {
    position: absolute;
    top: 25%;
    right: 5%;
    width: 90%;
    height: 60%;
    padding: 50px 16px 16px 16px;
    box-sizing: border-box;
  
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5));
  }
  
  .cardImage {
    position: absolute;
    top: 60%;
    height: 30%;
    right: 10%;
    width: 80%;
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardImage img {
    max-height: 100%;
  } */