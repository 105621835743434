.refresh-button {
  display: inline-block;
  margin: 12px auto;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 16px;
  padding: 12px 28px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.prompt-box-8w {
  background-color: #fff !important;
  color: #000 !important;
}

.prompt-button-8w {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.prompt-button-8w.button-primary-8w {
  background-color: #000 !important;
  color: #fff !important;
}
