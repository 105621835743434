.instruction-lipids{
    position: absolute;
    top: 48px;
    left: 84px;
    height: 52px;
    width: calc(100% - 168px);
    z-index: 3;
    padding: 8px 36px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(120, 120, 120, 0.7);
    border-radius: 24px;  
}

.instruction-lipids :nth-child(1) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/starch/MortarPestle.png') 50% 50%/contain no-repeat;
}

.instruction-lipids :nth-child(3) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/starch/Water.png') 50% 50%/contain no-repeat;
}

.instruction-lipids :nth-child(5) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/starch/Dropper.png') 50% 50%/contain no-repeat;
}

.instruction-lipids :nth-child(7) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/starch/TestTube.png') 50% 50%/contain no-repeat;
}

.instruction-button{
    height: 32px;
    width: 32px;
}

.instruction-arrow{
    height: 16px;
    width: 16px;
    background: url('https://bridgear.blob.core.windows.net/public/Biology/starch/arrow-right.png') 50% 50%/contain no-repeat;
}

.visible {
    display: flex;
}

.invisible {
    display: none;
}

.instruction-slide-up{
    top: -60px;
}

.instruction-slide-down{
    top: 48px;
}