.proximity-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 24%;
    padding: 8px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    text-align: center;
    font-family: GilroyMedium;
    z-index: 20;
}

.proximity-alert h1 {
    color: #FFA143;
}