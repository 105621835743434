#quizContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  transition: transform 0.2s ease-out;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5%;
}

.quizQuestion {
  width: 90%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  padding: 16px;
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 22px;
  font-family: GilroyBold;
  margin-bottom: 16px;
  min-height: 120px; /* Set a minimum height for the quizQuestion */
  max-height: 80%; /* Set a maximum height for the quizQuestion */
}

.quizHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.quizTitle {
  font-size: 14px;
  font-family: GilroyMedium;
  color: #FFA143;
}

.quizImage {
  width: 16px;
  height: auto;
}
.questionImage{
  margin: 0 auto;
  width: 45%;
  padding-bottom: 10px;
}
.wide{
  width: 65%!important;
}
.quizQuestion p {
  margin: 0;
}

.quizAnswers {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 16px; /* Add gap between answers */
}

.answer {
  width: 100%;
  height: 64px;

  border-radius: 24px;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.5));
  border: none;
  font-family: GilroyMedium;
  font-size: 14px;
}

.unanswered {
  background-color: rgba(255, 255, 255, 1);
  color: black;
}

.correctAnswer {
  background-color: rgb(2, 143, 33) !important;
  color: white !important;
}

.wrongAnswer {
  background-color: rgb(200, 0, 0) !important;
  color: white !important;
}

span.subscript {
  vertical-align: sub;
  font-size: 12px;
}

span.superscript {
  vertical-align: super;
  font-size: 12px;
}

@media (max-width: 768px) {
  .quizQuestion {
    font-size: 18px;
    min-height: 112px;
  }

  .answer {
    height: 56px;
  }
}

@media (max-width: 480px) {
  .quizQuestion {
    font-size: 16px;
    min-height: 92px;
  }

  .answer {
    height: 48px;
  }
}
