.instruction-micro{
    position: absolute;
    top: 48px;
    left: 84px;
    height: 52px;
    width: calc(100% - 168px);
    z-index: 3;
    padding: 8px 12px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(120, 120, 120, 0.7);
    border-radius: 24px;
    transition: all 0.2s ease-out; 
}

.instruction-micro :nth-child(1) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/Microbiology/UI_InoculatingLoop.png') 50% 50%/contain no-repeat;
    transform: rotate(45deg);
}

.instruction-micro :nth-child(3) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/Microbiology/UI_ThreeBeakers.png') 50% 50%/contain no-repeat;
}

.instruction-micro :nth-child(5) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/Microbiology/UI_Incubator.png') 50% 50%/contain no-repeat;
}

.instruction-micro :nth-child(7) {
    background: url('https://bridgear.blob.core.windows.net/public/Biology/Microbiology/UI_Observation.png') 50% 50%/contain no-repeat;
}

.instruction-button{
    height: 31px;
    width: 31px;
    
}

.instruction-arrow{
    height: 16px;
    width: 16px;
    background: url('https://bridgear.blob.core.windows.net/public/icons/arrow-right.png') 50% 50%/contain no-repeat;
}

.instruction-slide-up{
    top: -60px;
}

.inactive {
    opacity: 0.5;
}

@keyframes opacityLoop {
    0%, 100% {
        opacity: 0.1;
    }
    50% {
        opacity: 1;
    }
}

.opacity-animate {
    animation: opacityLoop 1s infinite; /* 2s duration for the entire loop. Adjust as needed. */
}
