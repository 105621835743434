span.subscript {
  vertical-align: sub;
  font-size: 12px;
}

span.superscript {
  vertical-align: super;
  font-size: 12px;
}

.smaller-title-annotation {
  font-size: 16px;
}