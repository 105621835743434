/* src/styles/trackingStatusPanel.css */
.trackingStatusPanel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 24px;
  z-index: 1000;
  text-align: center;
  font-size: 1.2rem;
  width: 80%;
}

.trackingStatusPanelTitle {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-family: GilroyBold;
  color: #FFA143;
}

.trackingStatusPanelContent {
  line-height: 1.4;
  font-family: GilroyMedium, sans-serif;
  color: #707070;
}

.trackingStatusPanelButtons {
  display: flex;
  justify-content: space-evenly ;
  margin-top: 16px;
}

.trackingStatusPanelButton {
  background-color: transparent;
  border: none;
  color: #FFA143;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  font-family: GilroySemiBold, sans-serif;
}

