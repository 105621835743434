body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'GilroyMedium';
}

root {
  display: block;
}

::placeholder {
  color: rgb(200, 200, 200);
}

// fonts

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
  url('./assets/fonts/Gilroy-Bold.ttf') format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"),
  url('./assets/fonts/Gilroy-SemiBold.ttf') format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "GilroySemiBoldItalic";
  src: local("GilroySemiBoldItalic"),
  url('./assets/fonts/Gilroy-SemiBoldItalic.ttf') format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),
  url('./assets/fonts/Gilroy-Medium.ttf') format("truetype");
  font-weight: regular;
}
