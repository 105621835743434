.hint-carousel {
  z-index: 99;
  background-color: rgb(255,253,3);
  width: 100vw;
  position: fixed!important;
  top: 0!important;
  height: calc(100vh - calc(100vh - 100%));
}

.carousel-item {
  width: 100%;
  position: fixed!important;
  top: 0!important;
  height: calc(100vh - calc(100vh - 100%));
}

.slider-text {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(0, 0, 0);
  font-weight: 700;
  z-index: 99;
}

.indicator-container {
  position: fixed;
  bottom: 56px;
  z-index: 99;
  pointer-events: none;
}

.indicator-icon-button svg {
  width: 25px;
  height: 25px;
  color: rgb(82, 84, 110);
}

.active-indicator-icon-button svg {
  color: rgb(255, 255, 255);
}

.close-hint-button {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 16px;
  z-index: 99;
  padding: 12px 28px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.hint-carousel.freez {
  pointer-events: none;
}