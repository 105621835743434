.hintButtonContainer {
    position: absolute;
    top: 112px;
    left: 16px;
  
    width: 52px;
    height: 52px;
  
    background-color: rgba(120, 120, 120, 0.7);
    border-radius: 50%;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    transition: transform 0.2s ease-out;
    z-index: 30;
  }
  
 .hintButtonContainer img {
    width: 75%;
    height: 75%;
}