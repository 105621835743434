.return-to-start-button {
    position: absolute;
    width: 52px;
    height: 52px;
  
    background-color: rgba(120, 120, 120, 0.7);
    border-radius: 50%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    top: 176px;
    left: 16px;
    z-index: 2;
}

.return-to-start-button img {
    width: 40%;
    height: 40%;
}

.return-to-start-button-invisible{
    display: none;
}

.return-to-start-button-visible{
    display: flex;
}