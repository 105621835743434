.answers-list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.answer-card {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background-color: #fff;
  color: #000;
  font-size: 11px;
  font-family: GilroyMedium;
  text-align: center;
  padding: 16px;
  list-style: none;
  user-select: none;
  margin-bottom: 16px;
}

.answer-card.draggable-item {
  z-index: 50;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
}

.answers-list.correct {
  pointer-events: none;
}

.answers-list.correct .answer-card {
  background-color: rgb(2, 143, 33);
  color: #fff;
}