.tap-place-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  
  .tap-place-button {
    font-size: 24px;
    padding: 12px 24px;
    background-color: transparent; /* Make the button background transparent */
    color: #F4F4F3;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  